















































































import Vue from 'vue'
import { Prop, Component, Mixins } from 'vue-property-decorator'

import VLoadSpinner from '~/components/VLoadSpinner.vue'
import { Dropdowns } from '~/mixins/dropdowns'
import { countryList } from '~/const/country'

import { UserStore } from '@/store/user'
import { ModalStore, ModalType, IModalParams } from '~/store/modal'
import { updateProducer } from '../api/profile'

@Component({ components: { VLoadSpinner } })
export default class SettingsPaymentsPage extends Mixins(Dropdowns) {
    ModalType = ModalType
    countryList = countryList
    searchInput = ''

    @ModalStore.State('isLoading') isLoading: boolean
    @UserStore.State('authenticatedUser') authenticatedUser: any

    get filteredCountry() {
        if (!this.countryList) return null
        return this.countryList.filter(element => element.match(new RegExp(this.searchInput, 'i'))).sort((a, b) => a.localeCompare(b))
    }

    @UserStore.Mutation('SET_PRODUCER_USER_INFO') SET_PRODUCER_USER_INFO: (user: Partial<IUserProducer>) => void
    @ModalStore.Mutation('SET_ACTIVE_MODAL') SET_ACTIVE_MODAL: (modalParams: IModalParams) => void

    async updateUserFields() {
        const au: IUserProducer = this.authenticatedUser
        const dispatchParams = {
            id: au.id,
            country: au.country,
            paypal_email: au.paypal_email
        }
        await updateProducer(dispatchParams)
        this.SET_ACTIVE_MODAL({ modal: ModalType.Success })
    }

    updateCountry(country: string) {
        this.SET_PRODUCER_USER_INFO({ ...this.authenticatedUser, country })
        this.closeMenuDropdown()
    }

    // async disconnectStripe() {
    //     try {
    //         await Vue.$axios.post('/disconnect_stripe/')
    //         // remove the property from user id, so the button v-if updates
    //         this.SET_PRODUCER_USER_INFO({ ...this.authenticatedUser, stripe_user_id: '' })
    //         // remove the query from url
    //         this.$router.push({ name: 'SellingPreferences' })
    //     } catch (err) {
    //         console.error(err)
    //     }
    // }

    // mounted() {
    //     if (this.stripeAuthCode) Vue.$axios.post('/connect_stripe/', { auth_code: this.stripeAuthCode })
    // }
}
